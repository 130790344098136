import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"
import BouttomScroll from "../../core/BouttomScroll"
import Countries from "../../../../static/countries.json"

export const SimpleBanner = ({
    title,
    subtitle,
    country,
    timeDuration,
    members,
    backgroundCover,
    ctaButton,
    ctaButton2,
    index,
    theme,
}) => {
      const textColor = backgroundCover
        ? "text-white "
        : "text-shark-500"
      const height = backgroundCover || index === 0 ? "h-screen" : ""
      const color = backgroundCover ? "bg-black" : "bg-blue-200"
      const op = backgroundCover ? "bg-opacity-70" : ""
      let kstyle = {
        backgroundColor: color,
      }

      if (backgroundCover?.mediaItemUrl) {
        kstyle = {
          ...kstyle,
          backgroundImage: `url(${backgroundCover?.mediaItemUrl})`,
        }
      }

      const getCountryName = countryCode => {
        const countryData = Countries.filter(
          country => country?.code === countryCode
        )
        if (countryData.length === 1) {
          return countryData[0]?.name
        }
        return null
      }

    return (
      <>
      {index===0?<BouttomScroll />:""}
        <div
          className={`w-full bg-cover bg-center ${height} overflow-hidden`}
          style={kstyle}
        >
          <div
            className={`flex items-center justify-center h-full  w-full ${op} ${color}`}
          >
            <div
              className="flex flex-col place-items-center items-center text-center py-16 m-4"
              data-aos={`${index === 0 ? "" : "fade-up"}`}
              data-aos-easing={`${index === 0 ? "" : "ease-out-back"}`}
              data-aos-duration={`${index === 0 ? "" : "700"}`}
            >
              {title && (
                <H2
                  title={title}
                  className={`${op} ${textColor} max-w-5xl`}
                  theme={index === 0 && backgroundCover ? "#ffffff" : "#24262B"}
                  index={index}
                />
              )}
              {title && theme && (
                <hr
                  className="h-1 w-40 mt-5 border-0"
                  style={{ backgroundColor: theme }}
                />
              )}
              {subtitle && <P title={subtitle} className={` ${textColor}`} />}
              <div className="flex md:flex-row flex-col md:gap-9 gap-1">
                {ctaButton && (
                  <a
                    href={ctaButton.url}
                    className="mt-5 md:mt-8 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                    // className="mt-5 py-3 hover:bg-blue-500 focus:bg-blue-600 px-8 bg-transparent border-white hover:border-transparent focus:border-transparent border rounded text-white"
                  >
                    {ctaButton?.title}
                  </a>
                )}
                {ctaButton2 && (
                  <a
                    href={ctaButton2?.url}
                    className="mt-5 md:mt-8 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                    // className="mt-5 py-3 hover:bg-blue-500 focus:bg-blue-600 px-8 bg-transparent border-white hover:border-transparent focus:border-transparent border rounded text-white"
                  >
                    {ctaButton2?.title}
                  </a>
                )}
              </div>
              {(country || members || timeDuration) && (
                <div className="mt-5 py-4 flex flex-wrap items-center justify-center gap-4">
                  {members && (
                    <div className="flex gap-2 items-center text-white bg-white bg-opacity-20 rounded-md px-4 py-2 font-medium text-sm">
                      <img
                        src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Team-member.webp"
                        alt="Members"
                        // title="Members"
                        className="h-5 w-5"
                        loading="lazy"
                      />
                      <span>{members} Members</span>
                    </div>
                  )}

                  {timeDuration && (
                    <div className="flex gap-2 items-center text-white bg-white bg-opacity-20 rounded px-4 py-2 font-medium text-sm">
                      <img
                        src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Project-duration.webp"
                        alt="Time Duration"
                        // title="Time Duration"
                        className="h-5 w-5"
                        loading="lazy"
                      />
                      <span>{timeDuration}</span>
                    </div>
                  )}
                  {country && getCountryName(country) != null && (
                    <div className="flex gap-2 items-center text-white bg-white bg-opacity-20 rounded px-4 py-2 font-medium text-sm">
                      <img
                        src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/country.webp"
                        alt="Country"
                        // title="Country"
                        className="h-5 w-5"
                        loading="lazy"
                      />
                      <span>{getCountryName(country)}</span>
                    </div>
                  )}
                </div>
            )}
            </div>
          </div>
        </div>
        </>
    )
}

export default SimpleBanner
